const FeedItem = ({item, passedStyles, noimage }) => {
  const handleClick = (e) => {
    e.preventDefault();
    console.log('no clickey');
    return false;
    };

  return (<div className="feed-item" key={"key"+item.slug} style={passedStyles}>
        
        {!noimage && 
            <div className="cover">
                <div className="img-wrap"><a href={"/" + item.contributors[0].email.split("@")[0].replace(".", "") + "/" + item.full_slug}><img src="https://picsum.photos/700/500?feed" alt="" /></a></div>
            </div>
        }
            <div className="byline">
                <div className="avatar-group">
                    <span className="b-avatar" style={{background: '#' + Math.floor(Math.random()*16777215).toString(16)}}>{item.contributors[0].name.split(" ").map((n)=>n[0]).join("")}</span>
                </div>
                <div className="author">
                    <a href={"/"+ item.contributors[0].email.split("@")[0].replace(".", "") }>{item.contributors[0].name}</a>
                    <br />
                    <span>{new Date(item.publish_date).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
                </div>
            </div>
            <div className="body">
                <h4><a href={"/" + item.contributors[0].email.split("@")[0].replace(".", "") + "/" + item.full_slug}>{item.title}</a></h4>
                <p><a href={"/" + item.contributors[0].email.split("@")[0].replace(".", "") + "/" + item.full_slug}>{item.excerpt}</a></p>
                <div className="tags">
                    {item.categories && item.categories.length > 0 && item.categories.map((cat,index) => 
                        index < 5 && <a key={index} onClick={handleClick} href="void(0);">{'#'+cat}</a>
                    )}
                </div>
            </div>
            <div className="footer">
                <a href={"/" + item.contributors[0].email.split("@")[0].replace(".", "") + "/" + item.full_slug}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25">
                        </path>
                    </svg>
                    {Math.floor(Math.random() * 10) + 1} minute read
                </a>
                <div className="ml-auto">
                    <a href={"/" + item.contributors[0].email.split("@")[0].replace(".", "") + "/" + item.full_slug}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z">
                            </path>
                        </svg>
                        {Math.floor(Math.random() * 100) + 1} Comments
                    </a>
                    <a onClick={handleClick} href="void(0);">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z">
                            </path>
                        </svg>
                        Read later
                    </a>
                </div>
            </div>
        </div>
  );

  };
  
  export default FeedItem;