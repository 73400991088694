import React from "react";
import HomeFeed from '../components/HomeFeed';
import TrendingTeams from '../components/TrendingTeams';
import TrendingTopics from '../components/TrendingTopics';

const Home = () => {
  return <div className='home-page'>
    <div className='content-feed-col'>
      <HomeFeed />
    </div>
    <div className='trending-latest-col'>
      <TrendingTeams />
      <hr className="divider" />
      <TrendingTopics />
    </div>
  </div>
};

export default Home;