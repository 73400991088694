import './css/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Layout from "./pages/Layout";
import TopMenu from './components/TopMenu';
import LeftNav from './components/LeftNavigation';

import Home from "./pages/Home";
import Teams from "./pages/Teams";
import Topics from "./pages/Topics";
import Topic from "./pages/Topic";
import Author from "./pages/Author";
import ContentPage from "./pages/ContentPage";
import NoPage from "./pages/NoPage";

export default function NewApp() {
  return (

    <div className="app-shell">
      <div className='app-nav-wrap'>
        <LeftNav />
      </div>
      <div className='app-content-wrap'>
        <TopMenu />
        <div className="component-wrapper">
        <BrowserRouter>
            <Routes>
              <Route index element={<Home />} />
              <Route path="topics" element={<Topics />} />
              <Route path="topics/:topic" element={<Topic />} />
              <Route path="teams" element={<Teams />} />
              <Route path="teams/:slug" element={<Teams />} />
              <Route path="/:author" element={<Author />} />
              <Route path="/:author/:slug" element={<ContentPage />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </BrowserRouter>        
        </div>
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <NewApp /> );