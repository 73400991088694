import React, { useState, useEffect } from 'react';


import Loading from "../components/LoadingBar.js";
import TopicCards from '../components/TopicCards.js';
import NoPage from "../pages/NoPage";

const Topics = () => {
  const [topicsObj, setTopicsObj] = useState([]);
  const [status, setStatus] = useState('idle');

useEffect(() => {
  setStatus('pending');

  async function getTopics() {
    await fetch('/json/taxonomy/taxonomy-new.json')
    .then((response) => response.json()).then((data) => {
      setTopicsObj(data);
      setTimeout(function(){ setStatus('topicsfound'); },1000); //to simulate a longer load
    }).catch((err) => {
      setTimeout(function(){ setStatus('notopics'); },1000); //to simulate a longer load
    });
  }

  getTopics();

}, []);

  if(status === 'idle' || status === 'pending'){
    return <Loading />;
  }else if( status === 'notopics'){
    return <NoPage type="topics" />;
  }else if(topicsObj !== [] && status === 'topicsfound'){
    console.log(topicsObj);
    return <TopicCards data={topicsObj} />;    
  }
};

export default Topics;