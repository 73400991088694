const TopicCard = ({data}) => {

  const filterTopics = (e) => {
    e.preventDefault();
    
    document.querySelector('#topic_filters a.active')?.classList.remove('active');
    e.target.classList.add('active');
    
    if(e.target.dataset.filter === 'all'){
      document.querySelectorAll('#our_tags .group').forEach( (element) => {
        element.style.display = 'block';
      });
    }else{
      document.querySelectorAll('#our_tags .group').forEach( (element) => {
        element.style.display = 'none';
      });
      document.querySelectorAll('#our_tags [data-filter="' + e.target.dataset.filter+'"]').forEach( (element) => {
        element.style.display = 'block';
      });
    }
    
    return false;
  };

  return (<div>
        <div className="mx-auto sm:px-6 lg:px-8 mt-8" id="topic_filters">
          <a href="void(0);" onClick={filterTopics} className="pill" data-filter="all">All</a>
        { 
            data.taggroups.map((topic)=>{
                return <a href="void(0);" onClick={filterTopics} className="pill" data-filter={topic.short_name} key={topic.short_name}>
                    {topic.name}
                </a>
            })
        }
        </div>

    <div className="mx-auto sm:px-6 lg:px-8 py-6 sm:pb-8 lg:pb-10" id="our_tags">      
            <div className="divide-y divide-gray-200 overflow-hidden sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-px sm:divide-y-0">
              
              { Object.entries(data.tags).map(([key, value]) => 
                <div key={'tag'+key} data-filter={value.type} className="group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                <div className="mt-2">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <a href={"/topics/"+value.short_name} className="focus:outline-none hover:text-blue-500">
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <b>#{value.name}</b>
                    </a>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{value.excerpt}</p>
                  <p className="mt-2 text-sm text-gray-500">{Math.floor(Math.random() * 1000) + 1} posts published</p>
                  <p className="mt-4"><button type="button" className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Follow</button></p>
                </div>
                <span className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-blue-400" aria-hidden="true">
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"></path>
                  </svg>
                </span>
              </div>
              )}
            </div>
          </div>
  </div>);
};
  
  export default TopicCard;