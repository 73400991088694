function TrendingTopics() {
    
    const topics = [
        {
            "name": "Amazon Web Services (AWS)",            
            "shortname": "aws"
        },
        {
            "name": "Application Modernization",
            "shortname": "application-modernization"
        },
        {
            "name": "CI/CD",
            "shortname": "ci-cd"
        },
        {
            "name": "Cloud-native app development",
            "shortname": "cloud-native-apps"
        }
        ,
        {
            "name": "COBOL",
            "shortname": "cobol"
        }
        ,
        {
            "name": "Containers",
            "shortname": "containers"
        }
        ,
        {
            "name": "Data Privicy",
            "shortname": "data-privicy"
        }
        ,
        {
            "name": "Data Science",
            "shortname": "data-science"
        }
    ];
    
    return (
      <div className="trending-topics">
        <h3>Trending Topics</h3>
        <div className="topics">
        {
            topics.map((topic)=>{
                return <a href={"/topics/"+topic.shortname} className="pill" key={topic.shortname}>
                    {topic.name}
                </a>
            })
        }
        </div>
        <a href="/topics" className="btn sm">
            Browse all topics
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd" />
            </svg>
        </a>
      </div>
    );
  }
  
  export default TrendingTopics;
  