import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Loading from "../components/LoadingBar.js";
import NoPage from "../pages/NoPage";
import HomeFeed from '../components/HomeFeed.js';

const Topic = () => {
  const [topicsObj, setTopicsObj] = useState([]);
  const [status, setStatus] = useState('idle');
  let { topic } = useParams();

  const handleClick = (e) => {
    e.preventDefault();
    console.log('no clickey');
    return false;
};

useEffect(() => {
  setStatus('pending');

  async function getTopics() {
    await fetch('/json/taxonomy/taxonomy-new.json')
    .then((response) => response.json()).then((data) => {
      setTopicsObj(data);
      setTimeout(function(){ setStatus('topicsfound'); },1000); //to simulate a longer load
    }).catch((err) => {
      setTimeout(function(){ setStatus('notopics'); },1000); //to simulate a longer load
    });
  }

  getTopics();

}, []);

  if(status === 'idle' || status === 'pending'){
    return <Loading />;
  }else if( status === 'notopics'){
    return <NoPage type="topics" />;
  }else if(topicsObj !== [] && topicsObj.tags[topic] && status === 'topicsfound'){
    return <div className="topics-page">
              <div className="overflow-hidden rounded-lg bg-white shadow my-8">
                <div className="bg-white p-6">
                  <div className="sm:flex sm:items-center sm:justify-between gap-12">
                    <div className="sm:flex sm:space-x-5">          
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-xl font-bold mb-4 text-gray-900 sm:text-2xl">#{topicsObj.tags[topic].name}</p>
                        <p className="text-sm font-medium text-gray-600">{topicsObj.tags[topic].excerpt}</p>
                      </div>
                    </div>
                    <div className="mt-5 flex justify-center sm:mt-0">
                      <a href="void(0);" onClick={handleClick} className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Follow</a>
                    </div>
                  </div>
                </div>
                <div className="topic-metrics bg-gray-50">
                  <div className='people text-left text-sm font-medium'>
                    <span className="text-gray-900 bold">{Math.floor(Math.random() * 1000) + 1} </span> 
                    <span className="text-gray-600">people following</span>
                  </div>
                  <div className='posts text-center text-sm font-medium'>
                    <span className="text-gray-900 bold">{Math.floor(Math.random() * 1000) + 1} </span> 
                    <span className="text-gray-600">posts published</span>
                  </div>
                  <div className='actions text-left text-sm font-medium'>
                    { topicsObj.tags[topic].product_link?.length > 0 &&
                      <a href={topicsObj.tags[topic].product_link[0].url} className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">{topicsObj.tags[topic].product_link[0].label}</a>
                    }
                  </div>
                </div>
              </div>

              <div className="topic-columns">
                <div className='create-column'>
                  <p className=""><button type="button" className="rounded-sm bg-blue-700 py-2 px-3 text-sm font-semibold text-white">Create content</button></p>
                  <hr className="my-6" />
                  <h3 className="text-sm font-medium text-gray-900 mb-2">Submission Guidelines</h3>
                  <p className="text-xs text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                  <hr className="my-6" />
                  <h3 className="text-sm font-medium text-gray-900 mb-2">{topicsObj.tags[topic].name}</h3>
                  <p className="text-xs text-gray-500">{topicsObj.tags[topic].description}</p>
                </div>
                <div className='content-feed-column'>
                  <div className="column-triggers">
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                      </svg>
                      <span>Submission guide</span>
                    </button>
                    <button>
                      <span>Latest Stuff</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                  <HomeFeed noimage passedStyles={{maxWidth:"100%"}} />
                </div>
                <div className='latest-column'>                                                
                  <h3 className="text-sm font-medium text-gray-900 mb-2">Latest {topicsObj.tags[topic].name} discussions</h3>
                  <div className="mb-4">
                    <p className="mb-2 text-sm text-gray-500">What's the biggest mistake you've ever made while coding?</p>
                    <p className="text-xs mt-1">{Math.floor(Math.random() * 100) + 1} comments</p>
                  </div>
                  <div className="mb-4">
                    <p className="mb-2 text-sm text-gray-500">What's the biggest mistake you've ever made while coding?</p>
                    <p className="text-xs mt-1">{Math.floor(Math.random() * 100) + 1} comments</p>
                  </div>
                  <hr className="my-6" />
                </div>
              </div>
          </div>
  }else{
    return <NoPage type="topics" />;
  }
};

export default Topic;