const NoPage = ({type}) => {

    if(type && type === 'author'){
      return <h1>The author you are looking for does not exist.</h1>;
    }else if(type && type === 'content'){
        return <h1>The content you are looking for does not exist.</h1>;
    }else{
      return <h1>404</h1>;
    }

  };
  
  export default NoPage;