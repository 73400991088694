import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Loading from "../components/LoadingBar.js";
import AuthorFeed from '../components/AuthorFeed.js';
import NoPage from "../pages/NoPage";

const Author = () => {
  const [authorObj, setAuthorObj] = useState([]);
  const [status, setStatus] = useState('idle');
  let { author } = useParams();

useEffect(() => {
  setStatus('pending');

  async function getAuthor() {
    await fetch('/json/authors/'+author+'.json')
    .then((response) => response.json()).then((data) => {
      setAuthorObj(data);
      setTimeout(function(){ setStatus('userfound'); },1000); //to simulate a longer load
    }).catch((err) => {
      setTimeout(function(){ setStatus('nouser'); },1000); //to simulate a longer load
    });
  }

  getAuthor();

}, []);

  if(status === 'idle' || status === 'pending'){
    return <Loading />;
  }else if( status === 'nouser'){
    return <NoPage type="author" />;
  }else if(authorObj !== [] && status === 'userfound'){
    return <div className="author-page">
            <div>
              <div className="-mx-4">
                <img className="h-32 w-full object-cover lg:h-48" src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80" alt="" />
              </div>
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-end sm:space-x-5">
                  <div className="flex -top-12 sm:-top-16 relative ">
                    <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src="https://picsum.photos/200/200?grayscale" alt="" />
                  </div>
                  <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1 relative -top-10 sm:-top-4">
                    <div className="min-w-0 flex-1">
                      <h1 className="truncate text-2xl font-bold text-gray-900">{authorObj._source.name} { authorObj._source.roles ? " | " + authorObj._source.roles[0] : "" }</h1>
                      <p className="mb-2">{authorObj._source.description}</p>
                    </div>
                    <div className="justify-stretch flex flex-col gap-2 align-top space-y-2 sm:flex-col sm:space-y-0 ">
                      <button type="button" className="w-full flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                        Message
                      </button>
                      <button type="button" className="w-full flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
                      </svg>
                        Website
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="author-columns">
                <div className='details-column'>

                  <div className="bg-white px-4 py-5 shadow sm:rounded-sm sm:px-6 mb-5">
                    {/* <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mb-3">Teams</h2>
                    <p>{Math.floor(Math.random() * 100) + 1} posts published</p>
                    <hr className="my-4" /> */}
                    <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mb-3">Activity</h2>
                    <p>{Math.floor(Math.random() * 100) + 1} posts published</p>
                    <p>{Math.floor(Math.random() * 50) + 1} comments</p>
                    <p>{Math.floor(Math.random() * 30) + 1} tags followed</p>
                  </div>

                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mb-3">Expertise in</h2>
                    { 
                        authorObj._source.skills.map((topic)=>{
                            return <a href={"/topics/" + topic} key={"key"+topic} className="pill">
                                {topic}
                            </a>
                        })
                    }
                  </div>

                </div>
                <div className='content-feed-column'>
                  {/* <h4 className="border-transparent text-gray-500 whitespace-nowrap text-sm font-medium">Authored content:</h4> */}
                  <AuthorFeed noimage passedStyles={{maxWidth:"100%"}} authorName={authorObj._source.name} authorUrl={author} />
                </div>
              </div>
  </div>
  }

};

export default Author;