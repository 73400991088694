import React, { useState, useEffect } from 'react';
import Loading from "../components/LoadingBar.js";

const NoPage = ({authorSlug, author}) => {

  const [authorObj, setAuthorObj] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    setStatus('pending');
  
    async function getAuthor() {
      await fetch('/json/authors/'+author+'.json')
      .then((response) => response.json()).then((data) => {
        setAuthorObj(data);
        setTimeout(function(){ setStatus('userfound'); },1000); //to simulate a longer load
      }).catch((err) => {
        setTimeout(function(){ setStatus('nouser'); },1000); //to simulate a longer load
      });
    }
  
    getAuthor();
  
  }, []);


  if(status === 'idle' || status === 'pending'){
    return <Loading />;
  }else if( status === 'nouser'){
    return <NoPage type="author" />;
  }else if(authorObj !== [] && status === 'userfound'){
    return (<div className='p-4 z-10 sticky top-10'>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-8">
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mb-2"><a href={authorSlug}>{authorObj._source.name}</a></h2>              
                <div className="justify-stretch mt-3 flex flex-col">
                  <button type="button" className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    Follow
                  </button>
                </div>
                <p className="text-sm mt-4"><b>{authorObj._source.roles[0]}</b></p>
                <p className="text-sm mt-4">{authorObj._source.description}</p>
                <div className="mt-6 flow-root">
                  <p className="text-sm mb-4"><b>Expertise:</b></p>
                  { 
                        authorObj._source.skills.map((topic)=>{
                            return <a href={"/topics/" + topic} className="pill" key={"key-"+topic}>
                                {topic}
                            </a>
                        })
                    }
                </div>
              </div>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-8">
              <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mb-2">More from <a href={authorSlug}>{authorObj._source.name}</a></h2>
              <ul>
                <li className="mb-4">
                  <p className="text-sm">
                    <a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</a>
                  </p>
                </li>
                <li className="mb-4">
                  <p className="text-sm">
                    <a href="#">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</a>
                  </p>
                </li>
                <li className="mb-4">
                  <p className="text-sm">
                    <a href="#">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
      )
    }
};
  
  export default NoPage;