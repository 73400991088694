import React,{useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import DOMpurify from "dompurify"

import Loading from "../components/LoadingBar.js";
import NoPage from "../pages/NoPage";
import FollowAuthor from "../components/FollowAuthor.js";
import ContentCategories from '../components/ContentCategories.js';

const ContentPage = () => {

  const [contentObj, setContentObj] = useState([]);
  const [status, setStatus] = useState('idle');
  let { slug } = useParams();

  const purifyOptions = {
    ADD_ATTR: ["onclick", "onchange", "allowfullscreen", "frameborder", "scrolling", "target"],
    ADD_TAGS: ["iframe", "link"]
  };

  useEffect(()=>{
    async function getContent() {
      await fetch('/json/content/'+slug+'.json')
      .then((response) => response.json()).then((data) => {
        setContentObj(data.results[0]);
        setTimeout(function(){ setStatus('contentfound'); },1000); //to simulate a longer load
      }).catch((err) => {
        setTimeout(function(){ setStatus('nocontent'); },1000); //to simulate a longer load
      });
    }
  
    getContent();

  },[])


  if(status === 'idle' || status === 'pending'){
    return <Loading />;
  }else if( status === 'nocontent'){
    return <NoPage type="content" />;
  }else if(contentObj !== [] && status === 'contentfound'){
    return (<article>
      
      <div className="content-wrap">
        <div className="content-body">
          <header className="flex flex-col">
            <div className="byline">
              <div className="avatar-group">
                <span className="b-avatar" style={{background: '#' + Math.floor(Math.random()*16777215).toString(16)}}>{contentObj.contributors[0].name.split(" ").map((n)=>n[0]).join("")}</span>
              </div>
              <div className="author">
              <a href={"/"+ contentObj.contributors[0].email.split("@")[0].replace(".", "") }>{contentObj.contributors[0].name}</a>
                <br />
                <time>{new Date(contentObj.publish_date).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</time>
              </div>
            </div>
            <h1 className="mt-6 text-4xl font-bold tracking-tight mb-4">{contentObj.title}</h1>
            <p className="text-lg mb-4">{contentObj.subtitle}</p>
            <hr />
          </header>
          <div className="prose" dangerouslySetInnerHTML={{ __html: DOMpurify.sanitize(contentObj.content, purifyOptions) }}></div>
          <ContentCategories title={contentObj.title} categories={contentObj.categories} />
        </div>
        <div className="content-aside">
          <FollowAuthor authorSlug={"/"+ contentObj.contributors[0].email.split("@")[0].replace(".", "") } author={contentObj.contributors[0].email.split("@")[0].replace(".", "") } />
        </div>
      </div>
      
    </article>);
  }
};

export default ContentPage;