const ContentCategories = ({title, categories}) => {

  if(categories?.length){
    return <div className="content-categories">      
      <hr />
      <p className="text-xs font-bold my-4">Tagged:</p>
    { categories.map((topic)=>{
        return <a href={"/topics/" + topic} className="text-xs pill" key={"key"+topic}>{topic}</a>
        })
    }
    <hr className="mt-4" />
  </div>;
  }else{
    return;
  }

  

};
  
export default ContentCategories;